import { motion, useInView } from "framer-motion";
import React, { useRef } from "react";
import { SVGs } from "../assets/svg";
import Logo from "../assets/svg/HeaderLogo.svg";
import HowToBuyGear from "../assets/svg/HowToBuyGear.svg";
import Statslight from "../assets/svg/StatsLight.svg";
import { toast } from "react-toastify";
import HowToBuyGlow from "./../assets/svg/HowToBuyGlow.svg";
import HowToBuyGlowTop from "./../assets/svg/HowToBuyGlowTop.svg";

// Animation variants
const headerVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } },
};

const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: { delay: i * 0.2, duration: 0.6, ease: "easeOut" },
  }),
};

const scrollingIconsVariants = {
  animate: {
    x: [0, -1000],
    transition: { repeat: Infinity, duration: 10, ease: "linear" },
  },
};

// Individual card component
const HowToBuyCard = ({ item, index }) => {
  const address = "0x48129b305a94d68ce4773ca1f0b55782ac735eac";
  const ref = useRef(null);
  const isInView = useInView(ref, {
    margin: "-30% 0px -40% 0px",
    once: false,
  });

  // Click handler for card actions
  const handleClick = () => {
    if (item.text === "Copy Contract Address") {
      navigator.clipboard.writeText(address).then(() => {
        // alert("Contract address copied to clipboard!");
        toast.success("Address copied successfully");
      });
    } else if (item.text === "Visit Decentralized Exchange") {
      const url =
        "https://app.uniswap.org/#/swap?&chain=mainnet&use=v2&outputCurrency=0x48129b305a94d68ce4773ca1f0b55782ac735eac";
      window.open(url, "_blank");
    }
  };

  return (
    <motion.div
      ref={ref}
      className={`howtobuy-card group flex items-stretch gap-6 px-8 md:w-auto w-full md:justify-start justify-center ${
        isInView && window.innerWidth <= 768 ? "htb-card-hover-active" : ""
      }`}
      custom={index}
      variants={cardVariants}
      transition={{ duration: 0.3 }}
      onClick={handleClick} // Attach click handler
      style={{ cursor: "pointer" }} // Indicate clickable area
    >
      <div className="relative p-6 flex-shrink-0">
        <img
          alt="stats-light"
          src={Statslight}
          className="absolute top-0 w-full left-1/2 transform -translate-x-1/2"
        />
        <SVGs.WalletSVG />
      </div>
      <p className="sm:text-[26px] text-[20px] font-extrabold text-white group-hover:text-black leading-tight md:whitespace-nowrap flex items-center">
        {item.text}
      </p>
    </motion.div>
  );
};

const HowToBuy = () => {
  const steps = [
    { text: "Copy Contract Address" },
    { text: "Visit Decentralized Exchange" },
    { text: "Swap $SUDO Token" },
  ];

  return (
    <div
      id="#howtobuy"
      className="relative z-20 flex flex-col w-full items-center justify-between gap-4 px-4 md:px-8 lg:px-16 py-8 md:py-16"
    >
      <img
        alt="howtobuy-gear"
        src={HowToBuyGear}
        className="absolute left-0 top-0 translate-x-[25%] md:translate-x-[100%] lg:translate-y-[0] translate-y-[-25%] sm:scale-100 scale-75"
      />

      <img
        alt="glow"
        src={HowToBuyGlow}
        className="absolute left-1/3 md:left-[15%] -top-1/4 md:-top-[40%]"
      />
      <img
        alt="glow"
        src={HowToBuyGlowTop}
        className="absolute left-0 md:-top-1/4"
      />

      {/* Header Section */}
      <motion.p
        className="text-[13px] font-semibold text-primary"
        initial="hidden"
        animate="visible"
        variants={headerVariants}
      >
        How to buy
      </motion.p>

      <motion.p
        className="text-[32px] font-semibold text-transparent bg-clip-text bg-primary text-center leading-tight"
        initial="hidden"
        animate="visible"
        variants={headerVariants}
      >
        Simple Steps to Acquire $SUDO
      </motion.p>

      <motion.p
        className="text-[14px] font-normal text-center text-white"
        initial="hidden"
        animate="visible"
        variants={headerVariants}
      >
        Follow the steps below to purchase $SUDO tokens with ease and start your
        investment journey today.
      </motion.p>

      <motion.div
        className="flex items-center justify-center text-[18px] font-normal text-center text-white gap-2"
        initial="hidden"
        animate="visible"
        variants={headerVariants}
      >
        CA: 0x48129b305a94d68ce4773ca1f0b55782ac735eac
      </motion.div>

      {/* Card Container */}
      <motion.div
        className="flex flex-wrap justify-center items-start gap-4 w-full max-w-[1000px] mx-auto"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
      >
        {steps.map((item, index) => (
          <HowToBuyCard key={index} item={item} index={index} />
        ))}
      </motion.div>

      {/* Scrolling Icons */}
      <div className="w-full overflow-hidden py-4 relative mt-12">
        <motion.div
          className="scrolling-icons flex items-center"
          variants={scrollingIconsVariants}
          animate="animate"
        >
          {[...Array(20)].map((_, index) => (
            <img key={index} src={Logo} alt="Scrolling Icon" className="mx-6" />
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default HowToBuy;
