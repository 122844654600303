import { motion } from "framer-motion";
import React from "react";
import VideoPlaceholder from "../assets/png/video-placeholder_new.png";
import { SVGs } from "../assets/svg";

// Animation variants
const leftSideVariants = {
  hidden: { opacity: 0, x: -50 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.8, ease: "easeOut" } },
};

const rightSideVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { duration: 0.8, ease: "easeOut" },
  },
};

const Telebot = () => {
  return (
    <div
      id="vision"
      className="relative z-30 flex flex-col md:flex-row w-full items-center justify-between gap-12 px-8 md:px-12 py-16 md:py-24"
    >
      {/* Left side with translation animation */}
      <motion.div
        className="flex flex-col gap-4 text-center md:text-left max-w-full md:max-w-[50%]"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
        variants={leftSideVariants} // Apply translate effect to the left side
      >
        <h1 className="relative text-[24px] md:text-[32px] font-semibold text-transparent bg-clip-text bg-primary font-['Noto Serif Thai'] leading-tight">
          <SVGs.TelebotCirleSVG className="absolute top-0 left-0 translate-y-[-50%] opacity-35 sm:scale-100 scale-75" />
          Revolutionize Your Workflow
          <br />
          with Sudo Lab AI Agent
        </h1>

        <p className="text-[14px] md:text-[16px] font-normal text-white">
          At Sudo Lab, we aim to simplify your digital journey with our
          AI-powered agent. It provides seamless access to a wide range of
          services, including domain management, containerization, hosting, RDP,
          blockchain node deployment, NaaS, IoT, cloud solutions, and website
          development, all in one platform.
        </p>

        <p className="text-[14px] md:text-[16px] font-normal text-white ">
          Our AI agent enhances efficiency by automating tasks and offering
          intelligent insights. A DApp is also being developed to integrate all
          services seamlessly, boosting productivity by up to 70%. Start
          streamlining your operations with Sudo Lab today.
        </p>

        <div className="inline-flex justify-center md:justify-start">
          <a
            className="group relative inline-block px-8 py-2 text-black hover:text-primary bg-primary transition-all ease-out duration-400  transform skew-x-[-30deg] border-none"
            style={{
              backgroundSize: "175% 100%",
              backgroundPosition: "center",
            }}
            href="https://t.me/SudoLabAIAgentBot"
            target="_blank"
            rel="noreferrer"
          >
            <span className="inline-block transform whitespace-nowrap skew-x-[30deg] relative z-10">
              Launch AI Agent
            </span>
            <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-[96%] -skew-x-[1deg] h-[90%] scale-0 group-hover:scale-100 transition-transform duration-700 bg-black"></div>
          </a>
        </div>
      </motion.div>
      <motion.img
        alt="telebot-video"
        src={VideoPlaceholder}
        className="w-[100%] md:w-[50%] mt-8 md:mt-0 rounded-[20px]"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
        variants={rightSideVariants}
      ></motion.img>
    </div>
  );
};

export default Telebot;
