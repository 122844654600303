import React, { useEffect } from "react";
import Header from "./components/header";
import Hero from "./components/hero";
import "./app.css";
import Features from "./components/features";
import Tokenomics from "./components/tokenomics";
import Telebot from "./components/telebot";
import HowToBuy from "./components/howtobuy";
import Roadmap from "./components/roadmap";
import Footer from "./components/footer";
import { useLocation } from "react-router-dom";

function App() {
  const location = useLocation();
  useEffect(() => {
    const currentSection = location.hash;
    console.log("scrolling", currentSection.split("#")[1]);
    const element = document.getElementById(currentSection.split("#")[1]);
    console.log(element);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [location]);
  return (
    <div className="relative overflow-clip bg-black">
      <Header />
      <Hero />
      <Features />
      <Tokenomics />
      <Telebot />
      <HowToBuy />
      <Roadmap />
      <Footer />
    </div>
  );
}

export default App;

