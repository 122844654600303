import React, { useRef } from "react";
import { motion, useInView } from "framer-motion"; // Import Framer Motion for animations
import RoadmapGear from "../assets/svg/RoadmapGear.svg";
import RoadmapBlock from "../assets/svg/RoadmapBlock.svg";
import RoadmapBG from "../assets/svg/RoadmapBG.svg";
import FooterGlowTop from "./../assets/svg/FooterGlowTop.svg";
import RoadmapGlow from "./../assets/svg/RoadmapGlow.svg";

// Pop-in animation variants
const popInVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: (i) => ({
    opacity: 1,
    scale: 1,
    transition: { delay: i * 0.2, duration: 0.6, ease: "easeOut" },
  }),
};

const roadmapPhases = [
  {
    title: "Phase 1",
    milestones: [
      "Launch Website and Branding",
      "$SUDO Token Launch",
      "Execute Social Media Marketing Campaigns",
      "Build Community Engagement",
      "Launch Sudo Lab AI Agent V1",
      "Release Whitepaper V1",
    ],
  },
  {
    title: "Phase 2",
    milestones: [
      "Expand to Blockchain Node Deployment",
      "Launch RDP Solutions",
      "Focus on Emerging Technologies and Trends",
      "Develop AI-Powered Containerization Tools",
      "CoinMarketCap (CMC) and CoinGecko (CG) Listing",
      "Form Strategic Partnerships",
    ],
  },
  {
    title: "Phase 3",
    milestones: [
      "Integrate Advanced AI Features",
      "Release Sudo Lab DApp Beta",
      "Publish Whitepaper V2",
      "Offer Customizable User Tools",
      "Enhance Infrastructure for Scalability",
      "Expand Partnership Networks",
    ],
  },
  {
    title: "Phase 4",
    milestones: [
      "Launch Subscription-Based Model",
      "Release Official Sudo Lab DApp",
      "Decentralized Tools with Blockchain Integration",
      "Build Ecosystem for $SUDO Token Adoption",
      "Develop Region-Specific Tools",
      "Explore Global Markets and Diverse Industries",
    ],
  },
];

const PhaseCard = ({ phase, index }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {
    margin: "-30% 0px -40% 0px",
    once: false,
  });

  return (
    <motion.div
      key={index}
      id="roadmap"
      className={`phase-card ${
        isInView && window.innerWidth <= 768 ? "phase-card-active" : ""
      }`}
      ref={ref}
      custom={index}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }}
      variants={popInVariants} // Pop-in animation for roadmap phases
      whileHover={{
        scale: 1.05,
      }}
    >
      <p className="text-[26px] mb-4 font-semibold phase-title text-transparent text-start bg-clip-text bg-primary">
        {phase.title}
      </p>
      {phase.milestones.map((milestone, i) => (
        <p
          key={i}
          className="text-[13px] relative font-normal text-white px-3 roadmap-bullets"
        >
          <span className="roadmap-bullets-tip"></span>
          {milestone}
        </p>
      ))}
    </motion.div>
  );
};

const Roadmap = () => {
  return (
    <motion.div
      className="relative flex flex-col md:flex-row w-full items-center justify-between px-4 md:px-8 lg:px-16 pt-8 md:pt-16 pb-36"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }}
    >
      <img
        alt="roadmap-block"
        src={RoadmapBlock}
        className="absolute right-0 bottom-0 translate-x-[15%] translate-y-[50%]"
      />
      <img
        alt="roadmap-block"
        src={RoadmapBG}
        className="absolute right-0 bottom-0 translate-y-[3rem]"
      />

      {/* Left Section */}
      <div className="flex flex-col md:flex-row items-stretch justify-start relative roadmap-box w-full z-50">
        <img
          src={RoadmapGear}
          alt="roadmap-gear"
          className="absolute left-0 top-0 translate-y-[-75%] translate-x-[-25%]"
        />
        <div className="md:max-w-[350px] border-b-[1px] border-primary md:border-r-[1px] flex flex-col items-center md:items-start justify-center gap-4 p-4 lg:p-8 w-full md:w-1/3">
          <p className="text-[13px] font-semibold text-primary">Roadmap</p>
          <p className="text-[32px] font-semibold text-transparent text-center md:text-left bg-clip-text bg-primary leading-tight">
            Project Milestones and Timelines
          </p>
          <p className="text-[13px] font-normal text-center md:text-left text-white">
            Check out the milestones set by the Sudo Lab team as we aim for
            continuous growth and exceptional service.
          </p>
        </div>

        {/* Roadmap Phases */}
        <div className="flex items-center p-4 lg:p-8 flex-grow justify-center w-full md:w-2/3 relative z-50">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 sm:w-auto w-full">
            {roadmapPhases.map((phase, index) => (
              <PhaseCard phase={phase} index={index} />
            ))}
          </div>
        </div>
      </div>

      {/* Gradient Glows */}
      <img
        src={FooterGlowTop}
        alt="glow"
        className="absolute -bottom-[60%] left-0"
      />
      <img
        src={RoadmapGlow}
        alt="glow"
        className="absolute -top-[10%] md:-top-[30%] -right-1/4 md:left-[15%]"
      />
      <img
        src={RoadmapGlow}
        alt="glow"
        className="absolute top-1/4 md:hidden -left-1/3"
      />
      <img
        src={RoadmapGlow}
        alt="glow"
        className="absolute top-2/4 md:hidden -right-1/3"
      />
      <img
        src={RoadmapGlow}
        alt="glow"
        className="absolute top-3/4 md:hidden -left-1/3"
      />
    </motion.div>
  );
};

export default Roadmap;
