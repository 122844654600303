import { motion } from "framer-motion"; // Import Framer Motion for animations
import React from "react";
import StatsLight from "../assets/svg/StatsLight.svg";
import TokenomicsGear from "../assets/svg/TokenomicsGear.svg";
import TokenomicsGlow from "./../assets/svg/TokenomicsGlow.svg";

// Animation variants
const containerVariants = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { duration: 0.8, ease: "easeOut" },
  },
};

const cardVariants = {
  hidden: { opacity: 0, rotate: -10, scale: 0.8 },
  visible: (i) => ({
    opacity: 1,
    rotate: 0,
    scale: 1,
    transition: { delay: i * 0.2, duration: 0.6, ease: "easeOut" },
  }),
};

const textVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const Tokenomics = () => {
  return (
    <motion.div
      className="relative z-20 flex flex-col w-full items-center sm:px-8 px-4 md:px-12 tracking-widest gap-3"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }} // Trigger animation when 30% is visible
      variants={containerVariants} // Scale and fade in for the entire component
      id="tokenomics"
    >
      <img
        alt="tokenomics-gear"
        src={TokenomicsGear}
        className="absolute top-0 right-0 md:translate-x-[-100%] sm:translate-y-[-50%] translate-x-[-50%] translate-y-[-75%]"
      />
      <img
        src={TokenomicsGlow}
        alt="glow"
        className="absolute top-1/3 md:-top-[55%]"
      />
      <img
        src={TokenomicsGlow}
        alt="glow"
        className="absolute top-[80%] -right-1/3 md:hidden"
      />
      <img
        src={TokenomicsGlow}
        alt="glow"
        className="absolute top-[140%] -left-1/3 md:hidden"
      />

      <p className="text-[13px] font-semibold text-primary">Tokenomics</p>
      <p className="text-[32px] font-semibold text-transparent bg-clip-text bg-primary leading-tight">
        Token Distribution
      </p>
      <p className="text-[14px] font-normal text-white text-center">
        The $SUDO token distribution is designed for fair and strategic
        allocation. It&apos;ll be listed on all major platforms,
        <br />
        ensuring broad visibility and accessibility.
      </p>

      {/* Tokenomics Card Grid */}
      <motion.div
        className="tokenomics-card flex flex-col sm:flex-row justify-evenly items-center sm:gap-0 gap-8 sm:items-stretch my-12"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
      >
        {[
          { label: "ETH", description: "Token Network" },
          { label: "SUDO", description: "Ticker" },
          { label: "5%", description: "Buy/Sell Tax" },
        ].map((item, index) => (
          <motion.div
            key={index}
            className="relative text-center flex flex-col items-center justify-center py-6 gap-3"
            custom={index}
            variants={cardVariants} // Card scale and rotate effect
          >
            <img
              alt="stats-light"
              src={StatsLight}
              className="absolute top-0 w-full"
            />
            <motion.p
              className="text-[32px] font-extrabold text-primary"
              variants={textVariants}
            >
              {item.label}
            </motion.p>
            <motion.p
              className="text-[13px] font-light text-white text-center"
              variants={textVariants}
            >
              {item.description}
            </motion.p>
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default Tokenomics;
